<template>
  <div class="indexes container">
    <div class="index-table">
      <div class="index-head">
        <h2 class="index-text">Payments List</h2>
        <h5 style="color: orange" v-if="selectedWallet">
          Wallet Balance : {{ selectedWalletAmount }}
        </h5>
        <div class="tabs">
          <ul>
            <li
              v-for="(tab, i) in pyTabs"
              :key="i"
              @click="changeTabs(tab)"
              :class="tab.isActive ? 'active' : ''"
            >
              <i :class="`bx ${tab.icon}`"></i>
              {{ tab.name }}
              <span class="counts">({{ tab.count }})</span>
            </li>
          </ul>
        </div>
      </div>
      <DropDown
        class="dp"
        :selectableItems="selectableWallets"
        v-model="selectedWallet"
        place-holder="Wallet select"
        :isLoading="walletListLoading"
      ></DropDown>
      <Histories :selectedWallet="selectedWallet" v-if="pyTabs[0].isActive" />
      <Payments :selectedWallet="selectedWallet" v-if="pyTabs[1].isActive" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Payments from "@/components/panelSections/payments/Payments.vue";
import Histories from "@/components/panelSections/payments/Histories.vue";

export default {
  data() {
    return {
      selectableWallets: [],
      selectedWallet: null,
      selectedWalletAmount: "",
      walletListLoading: false,
      pyTabs: [
        {
          name: "Histories",
          icon: "bx-intersect",
          isActive: true,
          count: 0,
        },
        {
          name: "Payments",
          icon: "bx-list-check",
          isActive: false,
          count: 0,
        },
      ],
      previousTab: {},
    };
  },
  components: {
    Payments,
    Histories,
  },
  watch: {
    selectedWallet(id) {
      if (id) {
        this.selectedWalletAmount = this.selectableWallets.find(
          (element) => element.id === id
        ).balance;
      }
    },
  },
  created() {
    // get session tab name and find it and go on that tab
    if (sessionStorage.getItem("last-py-tab")) {
      this.previousTab = this.pyTabs.find(
        (element) => element.name === sessionStorage.getItem("last-py-tab")
      );
      this.changeTabs(this.previousTab);
    }
    this.fetchAllWallets();
    this.fetchPayments();
    this.fetchHistories();
  },
  methods: {
    ...mapActions(["getAllWallets", "getAllPayments", "getAllHistories"]),
    fetchAllWallets() {
      this.walletListLoading = true;
      this.getAllWallets()
        .then((res) => {
          this.selectableWallets = res.data.result;
          this.selectedWallet = this.$route.params.id;
        })
        .finally(() => {
          this.walletListLoading = false;
        });
    },
    fetchHistories() {
      this.getAllHistories().then((res) => {
        this.pyTabs[0].count = res.data.result.total ? res.data.result.total : 0;
      });
    },
    fetchPayments() {
      this.getAllPayments().then((res) => {
        this.pyTabs[1].count = res.data.result.total ? res.data.result.total : 0;
      });
    },

    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.pyTabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.pyTabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
      this.handleSaveTab();
    },
    handleSaveTab() {
      //save active tab(object) name to session
      this.previousTab = this.pyTabs.find((element) => element.isActive === true);
      sessionStorage.setItem("last-py-tab", this.previousTab.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_indexes.scss";
.charge-btn {
  padding: 5px 0;
  border-radius: 5px;
  width: 180px;
  transition: all 0.5s ease;
}
</style>
