<template>
  <li class="payment-item">
    <div class="id center"><span class="responsive-label">ID</span>{{ payment.id }}</div>
    <div class="transaction Q-font center">
      <span class="responsive-label">Transaction</span>
      {{ payment.description ? payment.description : "Wallet Transaction" }}
    </div>
    <div class="amount center">
      <span class="responsive-label">amount</span>
      {{ payment.balance ? payment.balance : "0" }}
    </div>
    <div class="date center Q-font">
      <span class="responsive-label">date</span> {{ convertDate(payment.created_at, "DMMMYYYY") }}
    </div>
    <div class="creditor center">
      <span class="responsive-label">Creditor</span>
      {{ payment.creditor }}
    </div>
    <div class="wallet-name center Q-font">
      <span class="responsive-label">Wallet</span>
      {{ payment.wallet ? payment.wallet.name : "-" }}
    </div>
    <!-- <div class="actions">
      <Tooltip text="Payment Information">
        <button style="background: none" @click="showDetails(payment)">
          <i class="bx bxs-info-circle bx-tada" style="color: var(--color7)"></i>
        </button>
      </Tooltip>
    </div> -->
  </li>
</template>

<script>
import { remainingTime } from "../../../mixins/calendarFormater";

export default {
  props: ["payment"],
  mixins: [remainingTime],
  data() {
    return {};
  },
  components: {},
  methods: {
    showDetails(item) {
      this.$emit("detailItem", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.payment-item {
  align-items: center;
  background: var(--home-bg-lighter);
  border-radius: 0.9375em;
  margin: 0.5em 0;
  padding: 10px;
  display: grid;
  grid-template-columns: 5% 35% 10% 25% 10% 15%;
  transition: all 0.1s linear;
  // cursor: pointer;
  @include md {
    grid-template-columns: none;
    flex: 0 0 47.5%;
    box-shadow: 0.3125em 0.3125em 0.625em black;
    font-size: 14px;
  }
  @include sm {
    font-size: 12px;
    padding: 5px 10px !important;
    flex: 0 0 100%;
  }

  &:hover {
    box-shadow: 0.2125em 0.2125em 0.525em black;
    transform: scale(1.005);
    // box-shadow: -0.625em 0.625em 1.25em black;
    // padding: 8px 15px;
  }
  .bx {
    font-size: 1.875em;
  }
  .name {
    color: #e8eaf2;
    @include sm {
      font-size: 9px;
      margin: 0 2px;
    }
  }
  div {
    color: #8799e0;
  }
  .center {
    display: flex;
    justify-content: center;
    @include md {
      justify-content: space-between;
      font-style: normal !important;
    }
  }
  .transaction {
    font-size: 0.875em;
    font-style: italic;
    @include sm {
      font-size: 6px;
    }
  }
  .date {
    font-size: 0.875em;
    font-style: italic;
    @include sm {
      font-size: 6px;
    }
  }

  .responsive-label {
    display: none;
    color: white;
    @include md {
      display: inline-block;
    }
  }
}
</style>
