import { render, staticRenderFns } from "./Histories.vue?vue&type=template&id=0f0e523d&scoped=true&"
import script from "./Histories.vue?vue&type=script&lang=js&"
export * from "./Histories.vue?vue&type=script&lang=js&"
import style0 from "./Histories.vue?vue&type=style&index=0&id=0f0e523d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f0e523d",
  null
  
)

export default component.exports