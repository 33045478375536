<template>
  <li class="payment-item">
    <div class="id"><span class="responsive-label">ID</span>{{ payment.id }}</div>
    <div class="key center">
      <span class="responsive-label">Key</span> {{ payment.key ? payment.key : "---" }}
    </div>
    <div class="coin-name center">
      <span class="responsive-label">Coin</span> {{ payment.coin_name }}
    </div>
    <div class="amount center">
      <span class="responsive-label">Amount</span>
      {{ payment.amount ? payment.amount : "0" }}
    </div>
    <div class="date center Q-font">
      <span class="responsive-label">Created at</span>
      {{ convertDate(payment.created_at, "DMMMYYYY") }}
    </div>
    <div class="status center Q-font">
      <span class="responsive-label">Status</span> {{ statusCovertor(payment.status) }}
    </div>
    <div class="wallet-name center Q-font">
      <span class="responsive-label">Wallet Name</span>
      {{ payment.wallet ? payment.wallet.name : "-" }}
    </div>
    <div class="actions center">
      <Tooltip text="Payment Information">
        <button style="background: none" @click="showDetails(payment)">
          <i class="bx bxs-info-circle bx-tada" style="color: var(--color7)"></i>
        </button>
      </Tooltip>
    </div>
  </li>
</template>

<script>
import { remainingTime } from "../../../mixins/calendarFormater";

export default {
  props: ["payment"],
  mixins: [remainingTime],
  data() {
    return {};
  },
  components: {},
  methods: {
    showDetails(item) {
      this.$emit("showDetails", item);
    },
    // Status convertur for show in payment status
    statusCovertor(status) {
      if (status == 1) return "Expired";
      if (status == 2) return "Canceled";
      if (status == 10) return "Success";
      if (status == 0) return "Pending";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.payment-item {
  align-items: center;
  background: var(--home-bg-lighter);
  border-radius: 5px;
  margin: 0.5em 0;
  padding: 10px;
  display: grid;
  grid-template-columns: 5% 8% 11% 11% 21% 16% 16% 12%;
  transition: all 0.1s linear;
  // cursor: pointer;
  @include md {
    grid-template-columns: none;
    flex: 0 0 47.5%;
    box-shadow: 0.3125em 0.3125em 0.625em black;
    font-size: 14px;
  }
  @include sm {
    font-size: 12px;
    padding: 5px 10px !important;
    flex: 0 0 100%;
  }
  &:hover {
    box-shadow: 0.2125em 0.2125em 0.525em black;
    transform: scale(1.005);
    // box-shadow: -0.625em 0.625em 1.25em black;
    // padding: 8px 15px;
  }
  .bx {
    font-size: 1.875em;
  }
  div {
    color: #8799e0;
  }
  .center {
    display: flex;
    justify-content: center;
    @include md {
      justify-content: space-between;
      font-style: normal !important;
    }
  }
  .date {
    font-size: 0.875em;
    font-style: italic;
    @include sm {
      font-size: 10px;
    }
  }

  .responsive-label {
    display: none;
    color: white;
    @include md {
      display: inline-block;
    }
  }
}
</style>
