<template>
  <div class="table">
    <NoResult
      v-if="!payments || (!payments.length && !loading)"
      class="fit-height"
    ></NoResult>
    <div v-else class="table-wrapper">
      <BaseLoading v-if="loading" class="fit-height"></BaseLoading>
      <div class="table-content" v-else>
        <ul>
          <li class="headline">
            <div class="id">Id</div>
            <div class="key center">Key</div>
            <div class="coin-name center">Coin</div>
            <div class="amount center">Amount</div>
            <div class="date center">Created at</div>
            <div class="status center">Status</div>
            <div class="wallet-name center">Wallet</div>
            <div class="actions">Actions</div>
          </li>
          <PaymentItem
            v-for="(payment, i) in payments"
            :key="i"
            :payment="payment"
            @showDetails="showDetails"
          ></PaymentItem>
        </ul>
        <Pagination
          :total="total"
          :page="page"
          :lastPage="lastPage"
          :loading="loading"
          @nextPage="changePage"
        ></Pagination>
      </div>
    </div>
 <!-- payment details modal -->
    <BaseModal
      :dialog="paymentDetailsDialog"
      @close="paymentDetailsDialog = false"
      title="Payment Details"
    >
      <PaymentDetails
        :itemSelected="itemSelected"
      ></PaymentDetails>
    </BaseModal>
    <!-- payment details modal -->
    
  </div>
</template>

<script>
import PaymentItem from "./PaymentItem.vue";
import PaymentDetails from "./PaymentDetails.vue";
import { mapActions } from "vuex";
export default {
  props:['selectedWallet'],
  data() {
    return {
      payments: [
      ],
      page: 1,
      total: 0,
      lastPage: 1,
      loading: false,

      paymentDetailsDialog:false,
      itemSelected:null,
    };
  },
  components: {
    PaymentItem,PaymentDetails
  },
  watch:{
    selectedWallet(){
      this.fetchAllPayments()
    }
  },
  created() {
    this.fetchAllPayments();
    
  },
  methods: {
    ...mapActions(["getAllPayments"]),
    fetchAllPayments() {
      let params = new URLSearchParams({ page: this.page, per_page: 5 });
      if(this.selectedWallet)
      params.append('wallet_id',this.selectedWallet)
      this.loading = true;
      this.getAllPayments(params)
        .then((res) => {
          
          this.payments = res.data.result.data;
          this.total = res.data.result.total;
          this.lastPage = res.data.result.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showDetails(payment){
      this.itemSelected = payment;
      this.paymentDetailsDialog = true
    },
    changePage(val) {
      this.page = val;
      this.fetchAllPayments();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_table.scss";
ul{
@include md {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .headline {
    grid-template-columns: 5% 8% 11% 11% 21% 16% 16% 12% !important;
    @include md {
      display: none !important;
    }
  }
}
.fit-height {
  height: 60vh;
}
</style>
