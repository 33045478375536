<template>
  <ul class="payment-details">
    <li v-for="(value, name, i) in itemSelected" :key="i">
      <div class="info" v-if="name !== 'wallet'">
        <span class="name">{{ name }}</span
        ><span class="value">{{ value }}</span>
      </div>
      <div v-else class="inner-obj">
        <span class="in-obj-name">{{ name }} :</span>
        <div class="info in-obj-info" v-for="(v, n, i) in value" :key="i">
          <span class="name">{{ n }}</span
          ><span class="value">{{ v }}</span>
        </div>
      </div>
      <div class="line"></div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    itemSelected: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.payment-details {
  padding: 20px;
  background: linear-gradient(200deg, var(--color3), var(--home-bg));
  max-height: 80vh;
  overflow: auto;
  li {
    margin-bottom: 2px;
    .info {
      display: flex;
      justify-content: space-between;
      .name {
        color: var(--color7);
      }
      .value {
        color: var(--color5);
      }
    }
    .inner-obj {
      background: rgba(0, 0, 0, 0.541);
      padding: 3px;
      margin-top: 5px;
      .in-obj-name {
        color: orange;
      }
      .in-obj-info {
        padding: 0 8px;
      }
    }
    .line {
      background: linear-gradient(to right, var(--color7), var(--color5));
      height: 1px;
    }
  }
}
</style>
