<template>
  <div class="table">
    <NoResult
      v-if="!payments || (!payments.length && !loading)"
      class="fit-height"
    ></NoResult>
    <div v-else class="table-wrapper">
      <BaseLoading v-if="loading" class="fit-height"></BaseLoading>
      <div class="table-content" v-else>
        <ul>
          <li class="headline">
            <div class="id center">Id</div>
            <div class="transaction center">Transaction</div>
            <div class="amount center">Amount</div>
            <div class="date center">Created at</div>
            <div class="creditor center">creditor</div>
            <div class="wallet-name center">Wallet</div>
          </li>
          <HistoryItem
            v-for="(payment, i) in payments"
            :key="i"
            :payment="payment"
          ></HistoryItem>
        </ul>
        <Pagination
          :total="total"
          :page="page"
          :lastPage="lastPage"
          :loading="loading"
          @nextPage="changePage"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import HistoryItem from "./HistoryItem.vue";
import { mapActions } from "vuex";
export default {
  props: ["selectedWallet"],
  data() {
    return {
      payments: [],
      page: 1,
      total: 0,
      lastPage: 1,
      loading: false,
    };
  },
  components: {
    HistoryItem,
    //  AddWallet, ChargeWalletModal
  },
  watch: {
    selectedWallet() {
      this.fetchAllHistories();
    },
  },
  created() {
    this.fetchAllHistories();
  },
  methods: {
    ...mapActions(["getAllHistories"]),
    fetchAllHistories() {
      let params = new URLSearchParams({ page: this.page, per_page: 5 });
      if (this.selectedWallet) params.append("wallet_id", this.selectedWallet);
      this.loading = true;
      this.getAllHistories(params)
        .then((res) => {
          this.payments = res.data.result.data;
          this.total = res.data.result.total;
          this.lastPage = res.data.result.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    changePage(val) {
      this.page = val;
      this.fetchAllHistories();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_table.scss";
ul {
  @include md {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .headline {
    grid-template-columns: 5% 35% 10% 25% 10% 15% !important;
    @include md {
      display: none !important;
    }
  }
}
.fit-height {
  height: 60vh;
}
</style>
